import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import styles from './index.module.scss';
import Title from '../components/title/title';
import { Fragment } from 'react';
import aboutLeft from './images/aboutMain.png';
import aboutMain from './images/aboutLeft.png';
import aboutRight from './images/aboutRight.png';
import aboutWb from './images/aboutWb.png';

function About() {
  return (
    <Fragment>
      <Header></Header>
      <Title title='About Us'></Title>
      <main className={styles.main}>
        <div>
          <img className={styles.picture} src={aboutMain} alt='news' />
          <p className={styles.info}>
            We are an innovative digital smart sports company, whose business
            covers sports events, smart systems and sports equipment. We are
            committed to providing a full range of services and support for our
            customers.
          </p>
          <div className={styles.fl_spb}>
            <img className={styles.picture2} src={aboutLeft} alt='news' />
            <img className={styles.picture2} src={aboutRight} alt='news' />
          </div>
          <p className={styles.info}>
            <p>
              - Sports events are one of our core business areas. By providing
              professional event services, we organize high-quality events that
              meet the requirements of high-standard international events and
              create a fair and just competition environment for athletes from
              around the world.
            </p>
            <p style={{ marginTop: '20px' }}>
              - Smart systems are another important business area of our
              company. We provide customized solutions for sports venues, clubs
              and individuals by integrating advanced technologies and
              developing smart sports management systems and equipment.
            </p>
            <p style={{ marginTop: '20px' }}>
              - In addition, we are also committed to the development and
              production of sports equipment. By focusing on product quality and
              innovation, we design ergonomic equipment that enhances sports
              performance while ensuring comfort and safety.
            </p>
          </p>
          <div className={styles.fl_spb}>
            <img src={aboutWb} alt='world-boxing' />
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default About;
